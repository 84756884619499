import React, { useEffect, useState } from "react";
import { SceneType } from "../App";
import { useDispatch } from "react-redux";
import { voted2 } from "../redux/answerSlice";

const q2DataSource = {
    id: 'entry.68351919',
    values: ['発表順１．法人営業', '発表順２．コンタクトセンター', '発表順３．ロジスティクス', '発表順４．管理本部']
}

export default function BetForm(props: any) {
    const dispatch = useDispatch()
    const [a2, setA2] = useState<string|null>(null)
    
    const [showing, setShowing] = useState(false)
    
    useEffect(() => {
        if (props.scene === SceneType.VOTE2) {
            setShowing(true)
        }
    }, [props.scene])

    const canSubmit = () => {
        if (!a2) return false
        return true
    }

    const onSubmit = () => {
        // storeにフラグ保存
        dispatch(voted2())
        // 前の画面に戻る
        setShowing(false)
        setTimeout(()=>{
            props.setScene(SceneType.TOP)
            props.setSwingUp(false)    
        }, 500)
        // トースト
        props.showToast()
    }
    

    const onSuccess = () => {
        console.log('onSuccess')
    }

    return (
        <div className={`px-4 transition-opacity duration-500 ${props.scene===SceneType.VOTE2? '': 'hidden'} ${showing? 'opacity-100':'opacity-0 pointer-events-none'}`}>
            <form
                method="post"
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScV3h4wHJs4rjofoxWpKdT0r_dW96bVJ1FF71XsCmnzutlvbw/formResponse"
                target="hidden_iframe3"
                onSubmit={()=>onSubmit()}
            >
                <p className="text-sm text-gray-700 text-center leading-6">
                    本日はご来場いただきありがとうございました。<br />
                    最も印象に残った・称賛に値する事業部門発表を<br />選んで投票をお願いします。
                </p>
                
                <div className='border-2 border-orange-600 rounded-lg mt-6'>
                    <div className="p-4 border-b-2 border-b-orange-600">
                        <h5 className='text-orange-600'><span className='text-4xl mr-2 font-bold'>2.</span><span className="font-medium">事業部門発表</span></h5>
                    </div>
                    <ul className='p-4'>
                        {q2DataSource.values.map((data: string, i: number) => (
                            <li key={'q2-'+i}>
                                <label className={`block py-2 ${a2? a2===data? 'font-medium': 'text-gray-500': ''}`}>
                                    <input
                                        type="radio"
                                        name={q2DataSource.id}
                                        value={data}
                                        onChange={()=>setA2(data)}
                                        checked={a2===data}
                                        className='mr-1'
                                    />{data}
                                </label>
                            </li>
                        ))}                    
                    </ul>
                </div>
                <button type="submit" disabled={!canSubmit()} className=' mt-6 text-3xl font-bold w-full block bg-orange-600 text-white rounded-full py-4 text-center disabled:bg-gray-400'>
                    投票する
                </button>
            </form>
            <iframe
                name="hidden_iframe3"
                className="hidden"
                onLoad={() => onSuccess()}
            ></iframe>
        </div>
    )
}