import React, { useEffect, useState } from "react";
import { SceneType } from "../App";
import { useDispatch } from "react-redux";
import { reviewed } from "../redux/answerSlice";

export default function ReviewForm(props: any) {
    const dispatch = useDispatch()
    const [a1, setA1] = useState<string>('')
    const [a2, setA2] = useState<string>('')
    const [a3, setA3] = useState<string>('')
    const [a4, setA4] = useState<string>('')
    const [a5, setA5] = useState<string>('')
    
    const [showing, setShowing] = useState(false)
    
    useEffect(() => {
        if (props.scene === SceneType.REVIEW) {
            setShowing(true)
        }
    }, [props.scene])

    const canSubmit = () => {
        // if (!a1) return false
        if (!a2) return false
        // if (!a3) return false
        return true
    }

    const onSubmit = () => {
        console.log('onSuccess')
        // storeにフラグ保存
        dispatch(reviewed())

        // 前の画面に戻る
        setShowing(false)
        setTimeout(()=>{
            props.setScene(SceneType.TOP)
            props.setSwingUp(false)    
        }, 500)
        // トースト
        props.showToast()
    }
    

    return (
        <div className={`px-4 transition-opacity duration-500 ${props.scene===SceneType.REVIEW? '': 'hidden'} ${showing? 'opacity-100':'opacity-0 pointer-events-none'}`}>
            <form
                method="post"
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScemEDS5jNy3Aem8Mc0ZkxhgaEagugAYJWPa4ziV2aeV_AaJg/formResponse"
                target="hidden_iframe1"
                onSubmit={()=>onSubmit()}
            >
                <p className="text-sm text-gray-700 text-center leading-6">本日はご来場いただきありがとうございました。<br />アンケートにご協力をお願いいたします。</p>

                <div className='border-2 border-orange-600 rounded-lg mt-5'>
                    <div className="p-4">
                        <h5 className='text-orange-600'><span className='text-4xl font-bold mr-2'>Q1.</span><span className="font-medium">御社名</span></h5>
                        <input type="text" name="entry.46744615" value={a1} onChange={(e)=>setA1(e.target.value)} className="border-b-4 py-2 px-4 border-b-orange-600 block w-full mt-4 focus:outline-0 focus:border-b-blue-500" placeholder="元気でんき"/>
                    </div>
                </div>

                <div className='border-2 border-orange-600 rounded-lg mt-5'>
                    <div className="p-4">
                        <h5 className='text-orange-600'><span className='text-4xl font-bold mr-2'>Q2.</span><span className="font-medium">お名前（必須）</span></h5>
                        <input type="text" name="entry.1928327360" value={a2} onChange={(e)=>setA2(e.target.value)} className="border-b-4 py-2 px-4 border-b-orange-600 block w-full mt-4 focus:outline-0 focus:border-b-blue-500" placeholder="山田 太郎"/>
                    </div>
                </div>

                <div className='border-2 border-orange-600 rounded-lg mt-5'>
                    <div className="p-4">
                        <h5 className='text-orange-600'><span className='text-4xl font-bold mr-2'>Q3.</span><span className="font-medium">メールアドレス</span></h5>
                        <input type="email" name="entry.1737648602" value={a3} onChange={(e)=>setA3(e.target.value)} className="border-b-4 py-2 px-4 border-b-orange-600 block w-full mt-4 focus:outline-0 focus:border-b-blue-500" placeholder="info@genki-denki.co.jp"/>
                    </div>
                </div>

                <div className='border-2 border-orange-600 rounded-lg mt-5'>
                    <div className="p-4">
                        <h5 className='text-orange-600'><span className='text-4xl font-bold mr-2'>Q4.</span><span className="font-medium">全体を通じてのご感想</span></h5>
                        <textarea name="entry.670642531" rows={3} value={a4} onChange={(e)=>setA4(e.target.value)} className="border-b-4 py-2 px-4 border-b-orange-600 block w-full mt-4 focus:outline-0 focus:border-b-blue-500" placeholder="ご感想をお願いします"></textarea>
                    </div>
                </div>

                <div className='border-2 border-orange-600 rounded-lg mt-5'>
                    <div className="p-4">
                        <h5 className='text-orange-600 flex'><span className='text-4xl font-bold mr-2'>Q5.</span><span className="font-medium">当社でお役にたてることがありましたら是非お教えください</span></h5>
                        <textarea name="entry.1875723261" rows={3} value={a5} onChange={(e)=>setA5(e.target.value)} className="border-b-4 py-2 px-4 border-b-orange-600 block w-full mt-4 focus:outline-0 focus:border-b-blue-500" placeholder="ご自由にご回答下さい"></textarea>
                    </div>
                </div>

                <button type="submit" disabled={!canSubmit()} className=' mt-6 text-3xl font-bold w-full block bg-orange-600 text-white rounded-full py-4 text-center disabled:bg-gray-400'>
                    回答送信
                </button>
            </form>
            <iframe
                name="hidden_iframe1"
                className="hidden"
            ></iframe>
        </div>
    )
}