import { createSlice } from "@reduxjs/toolkit";

export const answerSlice = createSlice({
    name: 'answer',
    initialState: {
        vote1: false,
        vote2: false,
        review: false
    },
    reducers: {
        voted1: (state) => {
            state.vote1 = true
        },
        voted2: (state) => {
            state.vote2 = true
        },
        reviewed: (state) => {
            state.review = true
        },
        reset: (state) => {
            state.vote1 = false
            state.vote2 = false
            state.review = false
        }
    }
})

export const { voted1, voted2, reviewed, reset } = answerSlice.actions
export default answerSlice.reducer