import React from "react";
export default function Bg(props: any) {
    return (
        <div className='fixed z-0 top-0 left-0 w-screen h-1/2 bg-orange-600'>
        <div className='w-full overflow-hidden'>
          <div className='flex animation-typo-1'>
            <div>
              <div className='w-32 p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
              <div className='w-32 p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
            <div>
              <div className=' w-[18rem] p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
            <div>
              <div className='w-32 p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
              <div className='w-32 p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
            <div>
              <div className=' w-[18rem] p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
          </div>
          <div className='flex animation-typo-2'>
            <div>
              <div className=' w-[28rem] p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
            <div>
              <div className=' w-[28rem] p-1'>
                <img className="w-full block" src="./typo.svg" />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
}