import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import answerReducer from "./answerSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk'

const reducers = combineReducers({
    answer: answerReducer  
})
const persistConfig = {
    key: 'growthhack2022',
    storage
}
const persistReducers = persistReducer(persistConfig, reducers)
const store = configureStore({
    reducer: persistReducers,
    middleware: [thunk]
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store