import React, { useEffect, useState } from "react";
import { SceneType } from "../App";
import { useDispatch } from "react-redux";
import { voted1 } from "../redux/answerSlice";

const q1DataSource = {
    id: 'entry.1770376409',
    values: [
        {
            value: '発表順１番',    // value は GoogleForm と同じ文字列にするので変更NG
            title: '発表順１番.中原 博彰' // ← titleは自由に変更できる
        },
        {
            value: '発表順２番',
            title: '発表順２番.藤岡 昇太'
        },
        {
            value: '発表順３番',
            title: '発表順３番.福岡 晃次郎'
        }
    ]
}

export default function BetForm(props: any) {
    const dispatch = useDispatch()
    const [a1, setA1] = useState<string|null>(null)
    
    const [showing, setShowing] = useState(false)
    
    useEffect(() => {
        if (props.scene === SceneType.VOTE) {
            setShowing(true)
        }
    }, [props.scene])

    const canSubmit = () => {
        if (!a1) return false
        return true
    }

    const onSubmit = () => {
        // storeも書き換え
        dispatch(voted1())
        // 前の画面に戻る
        setShowing(false)
        setTimeout(()=>{
            props.setScene(SceneType.TOP)
            props.setSwingUp(false)    
        }, 500)
        // トースト
        props.showToast()
    }
    

    const onSuccess = () => {
        console.log('onSuccess')
    }

    return (
        <div className={`px-4 transition-opacity duration-500 ${props.scene===SceneType.VOTE? '': 'hidden'} ${showing? 'opacity-100':'opacity-0 pointer-events-none'}`}>
            <form
                method="post"
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfpvwGiU60BBFQRhj9ZSveUtMJyFXMiEZQMqMZQN3HRC5pczg/formResponse"
                target="hidden_iframe"
                onSubmit={()=>onSubmit()}
            >
                <p className="text-sm text-gray-700 text-center leading-6">
                    本日はご来場いただきありがとうございました。<br />
                    最も印象に残った・称賛に値するパートナー発表を<br />選んで投票をお願いします。
                </p>

                <div className='border-2 border-orange-600 rounded-lg mt-6'>
                    <div className="p-4 border-b-2 border-b-orange-600">
                        <h5 className='text-orange-600'><span className='text-4xl font-bold mr-2'>1.</span><span className="font-medium">パートナー投票</span></h5>
                    </div>
                    <ul className='p-4'>
                        {q1DataSource.values.map((data: {value: string, title: string}, i:number) => (
                            <li key={'q1-'+i}>
                                <label className={`block py-2 ${a1? a1===data.value? 'font-medium': 'text-gray-500': ''}`}>
                                    <input
                                        type="radio"
                                        name={q1DataSource.id}
                                        value={data.value}
                                        onChange={()=>setA1(data.value)}
                                        checked={a1===data.value}
                                        className='mr-1'
                                    />{data.title}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>

                
                <button type="submit" disabled={!canSubmit()} className=' mt-6 text-3xl font-bold w-full block bg-orange-600 text-white rounded-full py-4 text-center disabled:bg-gray-400'>
                    投票する
                </button>
            </form>
            <iframe
                name="hidden_iframe"
                className="hidden"
                onLoad={() => onSuccess()}
            ></iframe>
        </div>
    )
}