import React, { useEffect, useState } from 'react';
import Bg from './parts/Bg'
import VoteForm from './parts/VoteForm';
import Vote2Form from './parts/Vote2Form';
import ReviewForm from './parts/ReviewForm'
import { useSelector, useDispatch } from 'react-redux';
import { reset } from './redux/answerSlice';

export const SceneType = {
  TOP: 1,
  VOTE: 2,
  VOTE2: 4,
  REVIEW: 3,
} as const;

export type SceneType = typeof SceneType[keyof typeof SceneType];

function App() {
  const [swingUp, setSwingUp] = useState(false)
  const [scene, setScene] = useState<SceneType>(SceneType.TOP)
  const [toastShowing, setToastShowing] = useState(false)
  const vote1 = useSelector((state: any) => state.answer.vote1)
  const vote2 = useSelector((state: any) => state.answer.vote2)
  const review = useSelector((state: any) => state.answer.review)
  const dispatch = useDispatch()
  const showToast = () => {
    setToastShowing(true)
    setTimeout(()=>{
      setToastShowing(false)
    }, 6000)
  }
  useEffect(()=>{
    console.log('window.location.href', window.location.href)
    if(window.location.href.indexOf('?reset') > -1){
      setTimeout(()=>{
        dispatch(reset())
        const url = window.location.href.replace('?reset', '')
        window.location.href = url  
      }, 500)
    }
  }, [])

  // useEffect(()=>{
  //   console.log('useEffect', vote1, vote2, review)
  //   if(window.location.href.indexOf('/reset') > -1){
  //     if (!vote1 && !vote2 && !review) {
  //       const url = window.location.href.replace('/reset', '')
  //       window.location.href = url  
  //     }
  //   }
  // }, [vote1, vote2, review])
  return (
    <div className="App">
      <Bg />
      <div className={`relative z-10 transition-transform duration-700 ${swingUp? ' -translate-y-40': '-translate-y-0'}`}>
        <div className='pt-8 relative z-0'>
          <img src="./bg-cloud.svg" className='relative w-full drop-shadow-[0_-6px_7px_rgba(0,0,0,0.15)]' />
        </div>
        <div className='bg-white relative z-10'>
          <div className='-mt-6 se:-mt-8'>
            <img src="./logo.svg" className={`w-64 block mx-auto transition-transform duration-700 ${swingUp? ' scale-50': ' scale-100'}`} alt="growth hack 2022" />
          </div>
          <button onClick={()=>{setSwingUp(false); setScene(SceneType.TOP)}} className={`absolute top-12 left-4 flex justify-center items-center w-10 h-10 rounded-full border-2 border-orange-600 text-orange-600 transition-opacity duration-500 ${swingUp? 'opacity-100': 'opacity-0 pointer-events-none'}`}>
            <svg className='w-6 h-6' viewBox="0 0 24 24">
              <path fill="currentColor" d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
            </svg>
          </button>
          <div className='relative min-h-[calc(40vh)]'>
            <div className={`h-[calc(50vh)] se:h-[calc(40vh)] absolute top-0 left-0 pt-4 px-4 w-full flex flex-col justify-around transition-opacity duration-300 ${swingUp? 'opacity-0 pointer-events-none': 'opacity-100'}`}>
              <div className='grid grid-cols-2 gap-4'>
                <button onClick={()=>{setSwingUp(true); setScene(SceneType.VOTE)}} disabled={true} className='relative text-xl font-bold w-full block bg-red-600 text-white rounded-xl py-6 text-center disabled:bg-gray-400'>
                  {vote1? '投票済み': '受付終了'}
                  <span className='absolute w-10 h-10 bg-white border-4 border-black font-bold flex justify-center items-center -top-5 left-3 text-2xl text-black rounded-full'>1</span>
                </button>
                <button onClick={()=>{setSwingUp(true); setScene(SceneType.VOTE2)}} disabled={true} className='relative text-xl font-bold w-full block bg-amber-400 text-black rounded-xl py-6 text-center disabled:bg-gray-400 disabled:text-white'>
                  {vote2? '投票済み': '受付終了'}
                  <span className='absolute w-10 h-10 bg-white border-4 border-black font-bold flex justify-center items-center -top-5 left-3 text-2xl text-black rounded-full'>2</span>
                </button>                
              </div>
              <div>
                <p className=' text-gray-500 pl-16'>最後に本日のご感想を教えて下さい</p>
                <button onClick={()=>{setSwingUp(true); setScene(SceneType.REVIEW)}} disabled={review} className='relative mt-2 text-2xl font-bold w-full block border-4 border-orange-600 text-orange-600 rounded-xl py-2 text-center disabled:border-gray-400 disabled:text-gray-400'>
                  {review? '回答済み': '本日のご感想'}
                  <span className='absolute w-10 h-10 bg-white border-4 border-black font-bold flex justify-center items-center -top-8 left-3 text-2xl text-black rounded-full'>3</span>
                </button>
              </div>

              <p className='text-lg text-gray-500 text-center'>
                <a href="https://www.genki-denki.co.jp/privacy/" target="_blank" className='text-blue-600 underline'>プライバシーポリシー</a>
              </p>
            </div>
            <VoteForm scene={scene} setSwingUp={setSwingUp} setScene={setScene} showToast={showToast} />
            <Vote2Form scene={scene} setSwingUp={setSwingUp} setScene={setScene} showToast={showToast} />
            <ReviewForm scene={scene} setSwingUp={setSwingUp} setScene={setScene} showToast={showToast} />
          </div>

        </div>
      </div>
      <div className={`fixed flex justify-between items-center bottom-0 left-0 w-screen bg-green-600 px-6 py-4 z-50 text-white drop-shadow-[0_-3px_5px_rgba(0,0,0,0.15)] transition-opacity duration-500 ${toastShowing? 'opacity-100': ' pointer-events-none opacity-0'}`}>
        <p>ご投票ありがとうございました!</p>
        <button onClick={()=>setToastShowing(false)} className='text-white p-2 rounded'>
          <svg className='w-6 h-6' viewBox="0 0 24 24">
              <path fill="currentColor" d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default App;
